<template>
    <section class="op-mes">
        <div class="row mt-4 justify-content-center">
            <div class="col-12">
                <FullCalendar :options="calendarOptions" class="fullcalendar-styles" />
            </div>
        </div>
        <!-- partials -->
        
        <modal-detalle-tarea ref="modalDetalleTarea" />
    </section>
</template>
<script>
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
export default {
    components:{
        modalCrearTarea: () => import('./partials/modalCrearTarea.vue'),
        modalDetalleTarea: () => import('./partials/modalDetalleTarea.vue'),
    },
    data(){
        return{
            inputSearch:'',
            mostrarRequierenPago: false,
            calendarOptions: {
                 customButtons: {
                    listadoBitacora: {
                        text: 'Lista',
                        click: function() {
                            window.open('/oportunidades/vigentes/id/bitacora/lista', '_self')
                        }
                    }
                },
                plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
                initialView: 'dayGridMonth',
                locale: 'es',
                selectable: true,
                height: 'auto',
                headerToolbar: {
                    left: 'prev,next today',
                    center: 'title',
                    right: ''
                },
                buttonText:{
                    today: 'Hoy',
                    week: 'Semana',
                    month: 'Mes',
                },
                dateClick: function(info) {
                    alert('clicked ' + info.dateStr);
                },
                eventClick: this.verDetalleTarea,
                dayMaxEvents: true,
                events: [
                    {   
                        id: 0,
                        title: 'Salida',
                        start: '2021-10-01',
                        className: 'pendiente',
                    },
                    {
                        id:1,
                        title: 'Almuerzo',
                        start: '2021-10-01',
                        className: 'reservado'
                    },
                    {
                        title: 'halloween',
                        start: '2021-10-31',
                        className: 'actual',
                    },
                    {
                        title: 'Evento naranja',
                        start: '2021-10-04',
                        className: 'pendiente'
                    },
                    {
                        title: 'All Day Event',
                        start: '2021-10-10',
                        className: 'reservado'
                    },
                    {
                        title: 'All Day Event 2',
                        start: '2021-10-05',
                        className: 'actual'
                    },
                ]
            },
        }
    },
    methods:{
        crearTarea(){
            this.$refs.modalCrearTarea.toggle()
        },
        verDetalleTarea(){
            this.$refs.modalDetalleTarea.toggle()
        },
    }
}
</script>
<style lang="scss" scoped>

</style>